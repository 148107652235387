import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { SubmitButton } from '@Src/components/ui/SubmitButton';
import DataForm from '@Src/components/ui/DataForm';
import {  submitLabelAdvanceInquiry } from '@Src/actions/aloaded';
import i18n from '@Src/i18n';
import Text from '@Src/components/Text';

import { navigate } from 'gatsby';
import { useApp } from '@Src/context/AppContext';

interface AdvanceProps {
  locale: string;
}

const AdvanceInquirySignupView = ({
  locale = 'en-US'
}: AdvanceProps) => {
  let language = locale.split(/-/)[0]
  const fieldSets : Array<any> = [
    {
      slug: 'main',
      title: 'Main',
      fields: [
        {
          width: '200pt',
          slug: 'name',
          type: 'text',
          label: 'name',
          placeholder: 'Eg. Sven Svensson'
        },
        {
          width: '200pt',
          slug: 'labelName',
          label: i18n.trans(language, 'labelName'),
          type: "text",
          placeholder: 'eg. Universal Music Group AB'
        },
        {
          width: '200pt',
          slug: 'estimatedMonthlyStreams',
          label: i18n.trans(language, 'estimatedMonthlyStreams'),
          type: "number",
          placeholder: '1000'
        },
        {
          width: '200pt',
          slug: 'email',
          label: i18n.trans(language, 'email'),
          type: "email",
          placeholder: i18n.trans(language, 'email.placeholder')
        }
      ]
    }
  ]
  const [labelAdvanceInquiry, setLabelAdvanceInquiry] = useState<any>(null)
  const [postError, setPostError] = useState(false)
  const [submit, setSubmit] = useState(false)
  const { mode } = useApp();
  //const [openSnackbar, closeSnackbar] = useSnackbar()
  const onSubmit = (event: any) => {
    event.preventDefault();
    const { name, labelName, estimatedMonthlyStreams, email } = data
    // @ts-ignore
    if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
      // @ts-ignore
      window.dataLayer.push({
        type: 'event',
        event: 'submit_label_advance_inquiry',
        name,
        labelName,
        estimatedMonthlyStreams,
        email,
        eventCallback: () => {
          submitLabelAdvanceInquiry({ name, labelName, estimatedMonthlyStreams: parseInt(estimatedMonthlyStreams), email }).catch(error => {
            //  openSnackbar('Error submitting inquiry')
            }).then((result: any) => {
              if (!result) {
                setPostError(true)
                setSubmit(false)
              }
              if (result && result?.id) {
                window.location.href = (`/advance/label/submitted`)
              }
            }).catch(e => {
              throw e
            })
        },
        eventTimeout: 200
      })
    }
    setSubmit(true)
   
    return false;
  } 
  const [data, setData] = useState<Record<string, any>>(labelAdvanceInquiry ?? {firstName: '', lastName: '', email: '', labelName: '', artist: ''}) // useState({ firstName: 'Alexander', lastName: 'Forselius', labelName: 'Buddhaflow', artistUrl: 'https://open.spotify.com/artist/2FOROU2Fdxew72QmueWSUy?si=86e71821ecb143e1', email: 'drsounds@gmail.com'})
  return (
    <>
    <div>
      <Text theme={mode} htmlText={`See what funding you can secure for your label`} />
    </div>
    <div style={{padding: 0, opacity: submit ? 0.5 : 1}}>
      <form onSubmit={onSubmit} style={{padding: 0}}>
        {/* @ts-ignore */}
        <DataForm
          disabled={submit}
          fieldSets={fieldSets}
          idField={"slug"}
          collections={{}}
          defaultValue={data}
          // @ts-ignore
          onUpdate={(value) => setData(value)}
          theme={mode}
        />
        {/* @ts-ignore */}
        <SubmitButton
          isSubmitting={submit}
          theme={mode}
          label={i18n.trans(language, submit ? '' : 'get-estimate')}
        />
      </form>
    </div>
    <div>
      <Text theme={mode} htmlText={`I am an artist interested in an advance`} />
      
      {/* @ts-ignore */}
      <SubmitButton
        onClick={() => {
          navigate(`/advance`)
        }}
        buttonType="button"
        label={i18n.trans(language, 'click-here')}
        theme={mode}
      />
    </div>
    </>
  )
}

export default AdvanceInquirySignupView