import React, { ReactElement, useEffect, useState } from 'react';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import ContentfulModule from '@Src/components/ContentfulModule';
import SEO from '@Components/SEO';
import '@GraphQL/fragments';
import CalculateAdvancePage from '@Src/components/Advance/index';
import { navigate } from 'gatsby';
import '@GraphQL/fragments';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import AdvanceInquiryLabelSignupView from '@Src/components/Advance/Inquiry/LabelSignup';

export interface Props extends IPageProps {
  advanceInquiryId: string;
  data: {
    contentfulPageTypePitch: IPageData;
  };
} 

const AdvanceInquiryLabelPage = ({ advanceInquiryId, pageContext, data, location, transitionStatus }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = ''
    let locale = 'en-US'
    const language = locale.split(/-/)[0]
    const { setTheme: setPageTheme } = usePageTheme();
    const { mode, pageType, setPageType, setPageTitle } = useApp();
    const [value, setValue] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [content, setContent] = useState(null)
    const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

    useEffect(() => {
        if (pageType === 'index' && transitionStatus == 'exiting') {
            setLocalTransStatus(transitionStatus + 'Index');
        } else {
            setLocalTransStatus(transitionStatus);
        }
        if (transitionStatus == 'entering' || transitionStatus == 'entered') {
            setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'night');
            setPageTitle(title);
            setPageType(pageContext.type);
        }
    }, [transitionStatus]);

    useEffect(() => {
        if (transitionStatus == 'entered') {
          setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'dark');
        }
    }, [mode]);
 
    return (
      <ContentLayout type="signup" fullWidth={true} transitionState={localTransStatus}>
        <AdvanceInquiryLabelSignupView
          locale={locale}
        /> 
      </ContentLayout>
  );
};

export default AdvanceInquiryLabelPage;

